
.pointer {
    cursor: pointer
}

a {
    color: #2c234d;
    text-decoration: none;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

a:hover {
    color: #5838fc;
    text-decoration: none;
}

a:focus {
    outline: 0;
    text-shadow: none;
    box-shadow: none;
}

.feature-course-section-3 .sec-desc {
    margin-top: 38px;
}

.feature-course-item-3 {
    position: relative;
    background: #fff;
    border-radius: 15px;
    margin-bottom: 30px;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    -webkit-box-shadow: 0px 20px 50px 0px rgba(2, 56, 17, 0.10);
    -moz-box-shadow: 0px 20px 50px 0px rgba(2, 56, 17, 0.10);
    box-shadow: 0px 20px 50px 0px rgba(2, 56, 17, 0.10);
}

.feature-course-item-3 .fcf-thumb {
    /* min-height: auto; */
    margin: 0;
    position: relative;
    cursor: pointer;
}

.feature-course-item-3 .fcf-thumb:after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    content: '';
    border-radius: 15px 15px 0 0;
    background: rgba(14, 7, 38, .7);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.feature-course-item-3 .fcf-thumb:hover:after {
    visibility: visible;
    opacity: 1;
}

.feature-course-item-3 .fcf-thumb img {
    width: 100%;
    height: 200px;
    border-radius: 15px 15px 0 0;
}

.enroll {
    height: 40px;
    background: #fff;
    border-radius: 3px;
    position: absolute;
    z-index: 2;
    right: 40px;
    bottom: 30px;
    font-size: 13px;
    line-height: .8;
    color: #2c234d;
    font-weight: 600;
    text-align: center;
    display: inline-block;
    padding: 14px 26px;
    transform: scale(0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
}

.enroll:hover {
    background: #5838fc;
    color: #fff;
}

.feature-course-item-3 .fcf-thumb:hover .enroll {
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
}

.fci-details {
    position: relative;
    padding: 17px 35px 0;
}

.fci-details .c-cate {
    font-size: 12px;
    line-height: .8;
    color: #5d5a67;
    text-transform: uppercase;
    font-weight: 600;
    display: block;
    margin: 0 0 13px;
}

.fci-details .c-cate i {
    font-size: 14px;
    margin-right: 10px;
    position: relative;
    top: 2px;
}

.fci-details .c-cate:hover {
    color: #5838fc;
}

.fci-details h4 {
    font-size: 20px;
    line-height: 28px;
    color: #2c234d;
    font-weight: 600;
    margin-bottom: 15px;
}

.fci-details h4 a {
    color: inherit;
}

.fci-details h4 a:hover {
    color: #5838fc;
}


.fci-details .author a {
    color: #2c234d;
}

.fci-details .author a:hover {
    color: #5838fc;
}

.fci-details .author img {
    width: 40px;
    height: 40px;
}

.fci-details .price-rate {
    width: calc(100% + 70px);
    left: -35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 35px 9px;
}


.fci-details .ratings span {
    margin-left: 8px;
}

.author {
    display: inline-block;
    position: relative;
    margin: 0 0 15px;
}

.author img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
}

.author a {
    display: inline-block;
    font-size: 15px;
    color: #fff;
    line-height: .8;
    font-weight: 600;
    margin-left: 10px;
}

.price-rate {
    position: relative;
    border-top: 1px dashed #c3c2c8;
    width: calc(100% + 180px);
    left: -90px;
    display: flex;
    justify-content: space-between;
    padding: 18px 90px 15px;
}

.price-rate .course-price {
    color: #3fb65f;
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    display: block;
}


.price-rate .course-price-red {
    color: #5838fc !important;
}

.price-rate .course-price-black {
    color: #7e7c87 !important;
}

.price-rate .course-price span {
    font-size: 14px;
    font-weight: 600;
    color: #7e7c87;
    margin: 0;
    display: block;
    text-decoration: line-through;
}

.price-rate .ratings {
    margin: 0;
}

.price-rate .ratings span {
    color: #7e7c87;
}

.load-btn {
    margin-top: 20px;
    color: #5838fc;
    padding: 11px 35px;
    background: rgba(88, 56, 252, 0.1);
}

.load-btn i {
    display: inline-block;
    font-size: 20px;
    position: relative;
    top: 5px;
    margin-left: 7px;
}

.load-btn:hover {
    background: #5838fc;
    color: #fff;
}

.fci-details .price-rate .ratings {
    margin: 7px 0 0;
}

.fci-details .ratings span {
    margin-left: 8px;
}

.price-rate .ratings {
    margin: 0;
}

.price-rate .ratings span {
    color: #7e7c87;
}

.ratings {
    position: relative;
    display: inline-block;
    margin: 0 0 15px;
}

.ratings i {
    font-size: 12px;
    line-height: .8;
    color: #fcca31;
}

.ratings span {
    display: inline-block;
    font-weight: 400;
    line-height: .8;
    font-size: 14px;
    color: rgba(255, 255, 255, .7);
    margin: 0 0 0 12px;
}
